import * as React from 'react'
import * as ReactSpring from 'react-spring'

import PropTypes from 'prop-types'

export function FadeInOut(props) {
  const transitions = ReactSpring.useTransition(props.children, null, {
    from: {opacity: 0, flex: '1'},
    enter: {opacity: 1},
    leave: {opacity: 0},
  })

  return transitions.map((transition) => (
    <ReactSpring.animated.div key={transition.key} style={transition.props}>
      {transition.item}
    </ReactSpring.animated.div>
  ))
}

FadeInOut.propTypes = {
  children: PropTypes.node,
}

export function BounceInFromRight(props) {
  const transitions = ReactSpring.useTransition(props.children, null, {
    from: {
      opacity: 0,
      transform: 'translate3d(100%, 0, 0)',
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0%, 0, 0)',
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(100%, 0, 0)',
    },
    config: {mass: 1, tension: 170, friction: 16},
  })

  return transitions.map((transition) => (
    <ReactSpring.animated.div key={transition.key} style={transition.props}>
      {transition.item}
    </ReactSpring.animated.div>
  ))
}

BounceInFromRight.propTypes = {
  children: PropTypes.node,
}
