import * as Herz from '@rushplay/herz'
import * as Icons from './icons'
import * as React from 'react'
import * as ReactRouterDom from 'react-router-dom'
import * as ReactSpring from 'react-spring'
import * as ThemeUi from 'theme-ui'

export function GamePopup() {
  const [visible, setVisible] = React.useState(false)
  const translate = Herz.I18n.useTranslate()

  const transitions = ReactSpring.useTransition(visible, null, {
    from: {
      opacity: 0,
      transform: 'translate3d(0, 100%, 0)',
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0, 0%, 0)',
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(0, 100%, 0)',
    },
    config: {mass: 1, tension: 170, friction: 16},
  })

  React.useEffect(() => {
    const id = setTimeout(() => {
      setVisible(true)
      // Toggle after 5min
    }, 1000 * 60 * 5)

    return () => {
      clearTimeout(id)
    }
  }, [])

  return transitions.map(
    ({item, key, props}) =>
      item && (
        <ThemeUi.Box
          as={ReactSpring.animated.div}
          key={key}
          style={props}
          sx={{
            p: 2,
            position: 'fixed',
            right: '0px',
            bottom: '0px',
            zIndex: '10',
            width: '100%',
            maxWidth: '400px',
          }}
        >
          <ThemeUi.Box
            sx={{
              backgroundColor: 'nav-background',
              px: [2, 4],
              py: 4,
              borderRadius: 1,
              color: 'static-white',
              display: 'grid',
              position: 'relative',
            }}
          >
            <ThemeUi.Box
              onClick={() => setVisible(false)}
              sx={{
                'cursor': 'pointer',
                'position': 'absolute',
                'right': '-10px',
                'top': '-10px',
                'fontSize': 5,
                'color': 'static-white',
                'display': 'grid',
                'placeItems': 'center',
                'transition': 'transform 150ms ease-in-out',
                '&:hover': {
                  opacity: 0.9,
                },
                '&:active': {
                  transform: 'scale(0.9)',
                },
              }}
            >
              <Icons.Cancel />
            </ThemeUi.Box>
            <ThemeUi.Heading
              as="h2"
              sx={{
                fontSize: 3,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {translate('game-popup.title')}
            </ThemeUi.Heading>
            <ThemeUi.Box
              sx={{
                fontSize: 1,
                pt: 2,
                pb: 3,
              }}
            >
              {translate('game-popup.description')}
            </ThemeUi.Box>
            <ReactRouterDom.Link to="/?register=now">
              <ThemeUi.Button sx={{width: '100%'}}>
                {translate('game-popup.cta')}
              </ThemeUi.Button>
            </ReactRouterDom.Link>
          </ThemeUi.Box>
        </ThemeUi.Box>
      )
  )
}

Herz.I18n.Loader.preload(
  ['game-popup.cta', 'game-popup.description', 'game-popup.title'],
  GamePopup
)
